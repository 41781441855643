<template>
    <div>
        <ul class="nav flex-column nav-pills mt-5 ml-2">
            <li class="nav-item" @click.capture="clicked">
                <router-link disabled to="/start" class="nav-link" :class="{current: current(1), active: done(1)}">
                    <span>
                        <font-awesome-icon icon="check-double" fixed-width size="lg" v-if="done(1)" />
                        <font-awesome-icon icon="check" fixed-width v-else />
                    </span> Let's Install
                </router-link>
            </li>
            <li class="nav-item" @click.capture="clicked">
                <router-link disabled to="/license" class="nav-link" :class="{current: current(2), active: done(2)}">
                    <span>
                        <font-awesome-icon icon="check-double" fixed-width size="lg" v-if="done(2)" />
                        <font-awesome-icon icon="check" fixed-width v-else />
                    </span> License Agreement
                </router-link>
            </li>
            <li class="nav-item" @click.capture="clicked">
                <router-link disabled to="/check_list" class="nav-link" :class="{current: current(3), active: done(3)}">
                    <span>
                        <font-awesome-icon icon="check-double" fixed-width size="lg" v-if="done(3)" />
                        <font-awesome-icon icon="check" fixed-width v-else />
                    </span> Preinstall Checklist
                </router-link>
            </li>
            <li class="nav-item" @click.capture="clicked">
                <router-link disabled to="/verify" class="nav-link" :class="{current: current(4), active: done(4)}">
                    <span>
                        <font-awesome-icon icon="check-double" fixed-width size="lg" v-if="done(4)" />
                        <font-awesome-icon icon="check" fixed-width v-else />
                    </span> Verify your license
                </router-link>
            </li>
            <li class="nav-item" @click.capture="clicked">
                <router-link disabled to="/config" class="nav-link" :class="{current: current(5), active: done(5)}">
                    <span>
                        <font-awesome-icon icon="check-double" fixed-width size="lg" v-if="done(5)" />
                        <font-awesome-icon icon="check" fixed-width v-else />
                    </span> Installation Configuration
                </router-link>
            </li>
            <li class="nav-item" @click.capture="clicked">
                <router-link disabled to="/account" class="nav-link" :class="{current: current(6), active: done(6)}">
                    <span>
                        <font-awesome-icon icon="check-double" fixed-width size="lg" v-if="done(6)" />
                        <font-awesome-icon icon="check" fixed-width v-else />
                    </span> Create Account
                </router-link>
            </li>
            <li class="nav-item" @click.capture="clicked">
                <router-link disabled to="/completed" class="nav-link" :class="{current: current(7), active: done(7)}">
                    <span>
                        <font-awesome-icon icon="check-double" fixed-width size="lg" v-if="done(7)" />
                        <font-awesome-icon icon="check" fixed-width v-else />
                    </span> Installation Completed
                </router-link>
            </li>
            <li class="nav-item help">
                <a href="https://tecdiary.github.io/sbm-guide/" target="_blank" class="nav-link">
                    <span>
                        <font-awesome-icon icon="question-circle" fixed-width size="lg" />
                    </span> Help
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    methods: {
        current(n) {
            return this.$route.meta.cp == n;
        },
        done(n) {
            return this.$route.meta.cp > n;
        },
        clicked: function(e) {
            e.preventDefault();
        }
    }
};
</script>
